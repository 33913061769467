<template>
  <div class="entrustChild">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
      :extraParams="extraParams"
    >
      <template #headBtnSlot
        ><v-button text="新增" @click="toAdd"></v-button>
      </template>
      <template #searchSlot>
        <v-date-picker
          label="课程日期"
          v-model="createTime"
          type="daterange"
          formatValue="yyyy-MM-dd"
          format="yyyy-MM-dd"
          startPlaceholder="创建开始时间"
          endPlaceholder="创建结束时间"
        />
        <v-select
          clearable
          :options="timeDuringOps"
          v-model="searchParam.timeDuring"
          label="课程时间"
        />
        <v-select
          clearable
          :options="classStatusOps"
          v-model="searchParam.classType"
          label="课程状态"
        />
        <v-select
          clearable
          :options="reservationListOps"
          v-model="searchParam.type"
          label="预约形式"
        />
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" @click="toEdit(scope.row)" />
        <v-button text="删除" type="text" @click="toDelete(scope.row)" />
      </template>
    </v-list>
  </div>
</template>

<script>
import { getClassDataForManageUrl, deleteClassByIdUrl } from "./api.js";
import {
  timeDuringMap,
  setTimeDuringOps,
  classStatusMap,
  setClassStatusOps,
  reservationListMap,
  reservationListOps,
} from "./map.js";
import {
  createImgVNode,
  createAlinkVNode,
  createHTMLVNode,
} from "@/utils/utils.js";

export default {
  name: "entrustChild",
  data() {
    return {
      searchParam: {
        startTime: "",
        endTime: "",
        classType: undefined,
        timeDuring: undefined,
        type: "", //预约形式
      },
      timeDuringOps: setTimeDuringOps(),
      classStatusOps: setClassStatusOps(),
      tableUrl: getClassDataForManageUrl,
      headers: [
        {
          prop: "dayName",
          label: "课程日期",
        },
        {
          prop: "timeDuring",
          label: "课程时间",
          formatter: (row, prop) => {
            return timeDuringMap[row[prop]];
          },
        },

        {
          prop: "classStatus",
          label: "课程状态",
          // formatter: (row, prop) => {
          //   return classStatusMap[row[prop]];
          // },
        },
        {
          prop: "applyBeginTime",
          label: "报名开始时间",
        },
        {
          prop: "applyEndTime",
          label: "报名结束时间",
        },
        {
          prop: "applicantsMax",
          label: "可预约人数",
        },
        {
          prop: "type",
          label: "预约形式",
          formatter: (row, prop) => {
            return reservationListMap[row[prop]];
          },
        },
        {
          prop: "applicantsNum",
          label: "托育报名人数",
          align: "center",
          formatter: (row, prop) =>
            createAlinkVNode(this, row, prop, {
              text: row[prop],
              cb: () => this.toUserList(row),
            }),
        },
      ],
      reservationListOps: reservationListOps(), //预约形式
    };
  },
  computed: {
    createTime: {
      get() {
        return !this.searchParam.startTime && !this.searchParam.endTime
          ? []
          : [this.searchParam.startTime, this.searchParam.endTime];
      },
      set(val) {
        [this.searchParam.startTime, this.searchParam.endTime] = val || [
          "",
          "",
        ];
      },
    },
    extraParams() {
      return {
        tenantId: this.$store.state.app.userInfo.tenantId,
      };
    },
  },
  methods: {
    toAdd() {
      this.$router.push({
        name: "entrustChildForm",
      });
    },
    toEdit(row) {
      this.$router.push({
        name: "entrustChildForm",
        query: {
          id: row.scheduleId,
        },
      });
    },
    toUserList(row) {
      this.$router.push({
        name: "entrustChildUser",
        query: {
          scheduleId: row.scheduleId,
          type: row.type,
        },
      });
    },
    toDelete(row) {
      this.$confirm(
        `当前已有${row.applicantsNum}人报名，是否删除课程?`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        let params = {
          type: row.type,
          scheduleId: row.scheduleId,
        };
        this.$axios.post(deleteClassByIdUrl, null, { params }).then((res) => {
          if (res.code === 200) {
            this.$message.success("操作成功");
            this.$refs.list.search();
          }
        });
      });
    },
  },
};
</script>
<style lang="less" scoped>
.entrustChild {
  box-sizing: border-box;
  height: 100%;
}
</style>
